import cn from 'classnames';
import React, { FunctionComponent } from 'react';

export type DotsProps = {
  count: number;
  activeIndex: number;
  className?: string;
};

export const Dots: FunctionComponent<DotsProps> = ({ count, activeIndex, className }) => {
  return (
    <div className={cn('flex justify-center', className)}>
      {[...Array(count)].map((_, index) => (
        <span
          key={index}
          className={cn(
            'w-2 h-2 mr-2 transition-transform transform duration-200 bg-gray-900 border-2 border-black border-solid rounded-full',
            {
              'scale-125': index - 1 === activeIndex || index + 1 === activeIndex,
              'scale-150': index === activeIndex,
              'ml-2': index === 0,
            }
          )}
        />
      ))}
    </div>
  );
};
