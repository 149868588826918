import { Categories, CategoryCardInput } from '@/components/ui/Categories';
import { HomepageHeader, HomePageHeaderProps } from '@/components/ui/HomepageHeader';
import { RelatedLinks } from '@/components/ui/RelatedLinks';
import { RelatedLinksItemProps } from '@/components/ui/RelatedLinks/Item';
import { Languages, useLanguage, withLocale, Locale } from '@/contexts/LanguageContext';
import { getHours, getMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import compact from 'lodash/compact';
import { GetServerSideProps } from 'next';
import React from 'react';
import { SeoProps } from 'types';
import { createRelatedLinkBySlug } from 'utils/createRelatedLinkBySlug';
import { getFullNewsArticle, getLookup } from 'utils/lookupHelpers';
import { trimLanguageKeys } from '@/utils';

type HomePageProps = HomePageHeaderProps &
  SeoProps & {
    tiles: Array<CategoryCardInput>;
    relatedLinks: RelatedLinksItemProps[];
  };

const Home = ({ tiles, buildTime, relatedLinks }: HomePageProps) => {
  const { t } = useLanguage();

  return (
    <>
      <HomepageHeader buildTime={buildTime} />
      <Categories shouldRoute={false} categories={tiles} step={4} defaultActive={0} className="mt-3 md:mt-16" />
      <RelatedLinks items={relatedLinks} className="mt-3 md:mt-16" />
    </>
  );
};

export default withLocale.hoc(Home);

export const getServerSideProps: GetServerSideProps<HomePageProps, { lang: Languages }> = withLocale.getServerSideProps<HomePageProps>(
  async (strapiLookup, lang, _, t) => {
    const startPages = compact(strapiLookup.allStartPages);
    const tiles: Array<CategoryCardInput> = startPages.map((page) => {
      const clusterUrl = page.cluster?.type;
      let navigatorUrl = page.navigator_tab?.slug;

      if (navigatorUrl) navigatorUrl = '/navigator/' + navigatorUrl;
      const miscSiteUrl = page.miscellaneous_site?.slug;
      const url = clusterUrl || navigatorUrl || miscSiteUrl;
      return {
        img: page.illustration?.media?.url,
        text: page[lang]?.title || '',
        url: url ? `[lang]/${url}` : '#',
      };
    });

    const miscMeta = compact(strapiLookup.allFullMiscSites).find((miscSite) => miscSite?.slug.match(/^\/?\/?$/));
    const seoProps: SeoProps = {
      title: miscMeta?.[lang]?.title,
      seoSnippet: miscMeta?.[lang]?.description,
      keywords: compact(miscMeta?.[lang]?.tags).join(', '),
    };

    const date = new Date().valueOf();
    const timeZone = 'Europe/Berlin';
    const zonedDate = utcToZonedTime(date, timeZone);
    const minutes = getMinutes(zonedDate);
    const hours = getHours(zonedDate);

    const relatedLinks: RelatedLinksItemProps[] = compact([
      createRelatedLinkBySlug('regelleistung-antragstellung', lang),
      createRelatedLinkBySlug('ihre-persoenliche-beratungsfachkraft-im-jobcenter', lang),
      createRelatedLinkBySlug('leichte-sprache', lang),
      {
        type: 'request',
        text: t.general.contactForm,
        url: `/[lang]/kontaktformular`,
      },
    ]);

    // START Hotbutton code - TODO: delete me
    const news_article = getFullNewsArticle((doc) => doc?.slug === 'kontaktwege-fuer-ukrainische-schutzsuchende');
    if (news_article?.data) {
      const text = trimLanguageKeys(news_article.data, lang)?.[lang]?.title;
      relatedLinks.push({
        type: 'targetPage',
        text: text || '',
        url: '[lang]/news/kontaktwege-fuer-ukrainische-schutzsuchende',
      });
    }
    // END Hotbutton code

    return {
      tiles,
      buildTime: {
        minutes,
        hours,
      },
      relatedLinks,
      ...seoProps,
    };
  },
  getLookup
);
