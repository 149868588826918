import cn from 'classnames';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link } from '@/contexts/LanguageContext';
import { withinArrayBoundaries } from '@/utils';
import { Dots } from '../Dots';
import { Icon } from '../Icon';
import { useRouter } from 'next/router';
import Image from 'next/image';

export type CategoryCardInput = {
  img: string | undefined;
  text: string;
  url?: string;
  scrollToTop?: boolean;
};

export type CategoryCardProps = CategoryCardInput & {
  index: number;
  observer: IntersectionObserver | undefined;
  isActive: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, pos: number) => void;
};

export const CategoryCard: FunctionComponent<CategoryCardProps> = ({
  img,
  text,
  index,
  url,
  scrollToTop = true,
  onClick,
  observer,
  className,
}) => {
  const cardRef = useRef<HTMLDivElement & HTMLButtonElement>(null);

  useEffect(() => {
    if (!observer) return;
    // el is 100% a div here already even though it looks like it would be null
    const el = cardRef.current as HTMLDivElement;
    observer.observe(el);
    return () => observer.unobserve(el);
  }, [observer]);

  const handleFocus = (e) => {
    e.target.scrollIntoView({ block: 'nearest' });
  };

  const content = (
    <>
      <strong className="absolute inset-0 z-10 p-4 pt-6 text-xl font-bold leading-tight text-center direction-switch">{text}</strong>
      {img && <Image layout="intrinsic" width={492} height={630} objectFit="cover" className="h-full w-full" src={img} alt="" />}
    </>
  );

  const Wrapper = url ? 'div' : 'button';

  return (
    <Wrapper
      className={cn('relative flex-shrink-0 bg-white shadow-lg select-none hover:shadow-active rounded-xl Slide', className)}
      data-position={index}
      ref={cardRef}
      onFocus={handleFocus}
      onClick={(e) => {
        if (typeof onClick === 'function') {
          onClick(e, index);
        }
      }}
    >
      {url && (
        <Link href={url} scroll={scrollToTop}>
          <a className="relative flex items-end">{content}</a>
        </Link>
      )}
      {!url && content}
    </Wrapper>
  );
};

export type CategoriesProps = {
  categories: Array<CategoryCardInput>;
  onCardClick?: CategoryCardProps['onClick'];
  activeCardClassName?: string;
  inactiveCardClassName?: string;
  defaultActive?: number;
  className?: string;
  shouldRoute?: boolean;
  step?: number;
};

const intersectionOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.8,
};

export const Categories: FunctionComponent<CategoriesProps> = ({
  categories,
  onCardClick,
  activeCardClassName,
  inactiveCardClassName,
  defaultActive,
  className,
  shouldRoute = true,
  step = 1,
}) => {
  const router = useRouter();
  const [activeCategory, setActiveCategory] = useState(0);
  const [activeClickedCategory, setActiveClickedCategory] = useState(defaultActive ?? -2);
  const sliderRef = useRef<HTMLDivElement>(null);
  const allowScroll = useRef(true);

  const intersectionHandler = (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      if (entry.intersectionRatio < intersectionOptions.threshold) return;
      const position = entry.target.getAttribute('data-position');
      if (!position) throw new Error('The slides need a data-position attribute so we can change the dots correctly');
      setActiveCategory(parseInt(position));
    }
  };

  const [observer, setObserver] = useState<IntersectionObserver>();

  useEffect(() => {
    setObserver(new IntersectionObserver(intersectionHandler, intersectionOptions));
    return () => {
      observer?.disconnect();
    };
  }, []);

  const scroll = (action: 'scrollBy' | 'scrollTo') => (amount: number) => {
    if (!allowScroll) return;
    allowScroll.current = false;
    const slider = sliderRef.current as HTMLDivElement;
    slider.scrollTo({
      left: amount,
      behavior: 'smooth',
    });
    setTimeout(() => (allowScroll.current = true), 500);
  };
  const scrollTo = scroll('scrollTo');

  const scrollToCard = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>, offset: number) => {
    const slide = e.currentTarget;
    const slideMarginLeft = 24;
    const slideWidth = slide.getBoundingClientRect().width;
    const slidePosition = Number(slide.dataset.position);
    const scrollToX = (slideWidth + slideMarginLeft) * Math.max(0, slidePosition - offset);
    scrollTo(scrollToX);
    setActiveClickedCategory(slidePosition);
  };

  const clickCard = (pos: number, skipCheck = false) => {
    if (!skipCheck && pos === activeClickedCategory) return;
    const slide = sliderRef.current?.querySelector(`.Slide[data-position="${pos}"]`) as HTMLElement;
    const link = slide.querySelector('a');
    const href = link?.getAttribute('href');
    if (href && shouldRoute) {
      const hasPreview = !!router.query.preview;
      router.push(`${href}${hasPreview ? '?preview=true' : ''}`);
    }
    slide.click();
  };

  useEffect(() => {
    if (defaultActive && defaultActive > 0) {
      clickCard(defaultActive, true);
    }
  }, [defaultActive]);

  return (
    <div>
      <Dots count={categories.length} activeIndex={activeCategory} className="mt-5 md:hidden" />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div className={cn('py-4 px-2 flex overflow-x-auto gap-6 Slider', className)} ref={sliderRef}>
        {categories.map((category, index) => (
          <CategoryCard
            key={index}
            index={index}
            scrollToTop={false}
            isActive={index === activeCategory}
            className={cn(index === activeClickedCategory || activeClickedCategory === -2 ? activeCardClassName : inactiveCardClassName, {
              'cursor-pointer': onCardClick,
            })}
            observer={observer}
            {...category}
            onClick={(e, pos) => {
              scrollToCard(e, 0);
              if (typeof onCardClick === 'function') {
                onCardClick(e, pos);
              }
            }}
          />
        ))}
      </div>
      {categories.length > 4 && (
        <div className="relative hidden md:block">
          <Dots count={categories.length} activeIndex={activeClickedCategory} className="mt-5" />
          <div className="absolute top-0 right-0 flex transform -translate-y-1">
            <button
              className="hover:bg-dark-primary w-14 h-14 rounded-full ml-4 group"
              onClick={(e) => {
                if (activeClickedCategory < 0) return clickCard(0);
                const position = withinArrayBoundaries(activeClickedCategory, categories);
                const max = categories.length;
                if (position - step < 0) {
                  return clickCard(max - 1);
                } else {
                  return clickCard(position - step);
                }
              }}
            >
              <Icon name="Right Arrow" className="ml-6 group-hover:hidden transform scale-150 rotate-180" />
              <Icon name="Right Arrow White" className="ml-6 group-hover:block transform hidden scale-150 rotate-180" />
            </button>
            <button
              className="hover:bg-dark-primary w-14 h-14 rounded-full group"
              onClick={(e) => {
                if (activeClickedCategory < 0) return clickCard(0);
                const position = withinArrayBoundaries(activeClickedCategory, categories);
                const max = categories.length;
                if (position + step >= max) {
                  clickCard(0);
                } else {
                  clickCard(position + step);
                }
              }}
            >
              <Icon name="Right Arrow" className="ml-6 group-hover:hidden transform scale-150" />
              <Icon name="Right Arrow White" className="ml-6 group-hover:block transform hidden scale-150" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
